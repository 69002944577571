<template>
  <div>
    <Table
      :loading="loading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-btns="tableBtns"
      :show-pagination="false"
    >
      <template v-slot="{ col, row }">
        <div v-if="col.prop === 'map'" class="flex-c">
          <img class="cursor" src="@/assets/images/common/tansport.svg" @click="openMap(row)">
        </div>
        <div v-if="col.prop === 'price'">
          <!-- <span class="mr5">{{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}</span>
          <span :class="$CONSTANT.taxColorMap[+row.needTax]" class="ml5">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span> -->
          <span v-if="+row.priceType===1">
            <span class="mr5">{{ $parseNumber(row.price) }}{{ col.rulesObj[+row.priceUnit]||'-' }}</span>
            <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
          </span>
          <span v-if="+row.priceType===2">
            <span class="mr5">{{ $parseNumber(row.showPrice) }}{{ col.rulesObj[+row.priceUnit]||'-' }}</span>
            <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
          </span>
        </div>
        <div v-if="col.prop === 'infoPrice'" class="ellipsis">
          <span>{{ +row.msgPriceType===2?$parseNumber(row.msgPrice)+'元/车':'-' }}</span>
        </div>
        <!-- <div v-if="col.prop === 'plan'" class="plan-time">
          <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
          <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
        </div> -->
        <div v-if="col.prop === 'lineName'">
          <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
        </div>
        <div v-if="col.prop === 'driverName'">
          <div>
            {{ row.driverName||'-' }}
          </div>
          <div>
            {{ row.driverPhone||'-' }}
          </div>
        </div>
        <div v-if="col.prop === 'escortName'">
          <div>
            {{ row.escortName||'-' }}
          </div>
          <div>
            {{ row.escortPhone||'-' }}
          </div>
        </div>
        <div v-if="col.prop === 'realLoadNum'">
          <div>
            {{ row.carAddressVOList?numberCalculation(row.carAddressVOList[0].receiptNum,1,1000,'div'):'-' }}吨
          </div>
          <div v-if="row.carAddressVOList">
            {{ row.carAddressVOList[0].receiptTime|parseTime }}
          </div>
        </div>
        <div v-if="col.prop === 'realUnLoadNum'">
          <div>
            {{ (row.carAddressVOList&&row.carAddressVOList[1])?numberCalculation(row.carAddressVOList[1].receiptNum,1,1000,'div'):'-' }}吨
          </div>
          <div v-if="row.carAddressVOList&&row.carAddressVOList[1]">
            {{ row.carAddressVOList[1].receiptTime|parseTime }}
          </div>
        </div>
        <div v-if="col.prop === 'loss'">
          {{ numberCalculation(row.carAddressVOList?row.carAddressVOList[0].receiptNum:'',(row.carAddressVOList&&row.carAddressVOList[1])?row.carAddressVOList[1].receiptNum:'',1000,'minus') }}吨
        </div>
        <!-- <div v-if="col.prop === 'isWhite'">
          <span v-if="row.wayBill">{{ row.wayBill.receiveBill?row.wayBill.receiveBill.isWhite===1?'申请卸结':'不申请卸结':'-' }}</span>
        </div> -->
        <div v-if="col.prop === 'action'" class="action-wrap">
          <div class="main-c" @click="toDetail(row)">
            查看
          </div>
          <div v-if="[9].includes(+row.state)&&['PT005'].includes($store.state.common.AUV_ROLE_CODE)" class="main-c" @click="openDialog(row)">
            磅单核定
          </div>
        </div>
      </template>
    </Table>
    <BandDialog v-if="dialogVisible" :dialog-visible.sync="dialogVisible" :car-order-id="clickOrderId" @savePound="savePound" />
  </div>
</template>

<script>
import poundList from '@/api/pound';
import vehicleBill from '@/api/transport/vehicleBill';
import BandDialog from '@/components/OrderBusiness/bandDialog';
import { parseTime, numberCalculation } from '@/utils';
export default {
  components: { BandDialog },
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    searchForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      numberCalculation,
      loading: false,
      tableBtns: [
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
        {
          label: '磅单导出',
          type: 'default',
          evt: () => {
            this.bandOnExport();
          },
        },
      ],
      tableCols: [
        { label: '车单编号', prop: 'carSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); } },
        { label: '运单编号', prop: 'qxTSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toWayBillDetail(data); } },
        { label: '履约动态', prop: 'map', type: 'customize', width: '85' },
        { label: '上报价格', prop: 'price', type: 'customize', width: '135', rulesObj: this.$CONSTANT.transportPriceUnitMap.O },
        { label: '信息费', prop: 'infoPrice', type: 'customize' },
        { label: '车牌号', prop: 'truckNo' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize' },
        { label: '押运员', prop: 'escortName', type: 'customize' },
        { label: '派单时间', prop: 'createTime', type: 'filterTime', width: '175' },
        { label: '承运商', type: 'userInfoCard', width: 165, userType: 2 },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '产品名称', prop: 'goodsName' },
        // { label: '是否申请卸结', prop: 'isWhite', type: 'customize' },
        { label: '装货', prop: 'realLoadNum', type: 'customize', width: '145' },
        { label: '卸货', prop: 'realUnLoadNum', type: 'customize', width: '145' },
        { label: '损耗', prop: 'loss', type: 'customize', width: 80 },
        { label: '状态', prop: 'state', type: 'transition', rulesObj: this.$CONSTANT.carOrderStateMap.O },
        // { label: '装卸计划时间', prop: 'plan', type: 'customize', width: '160' },
        { label: '操作', prop: 'action', type: 'customize', fixed: 'right', align: 'left' },
      ],
      dialogVisible: false,
      bandInfo: {},
      clickOrderId: '',
    };
  },

  methods: {
    // ...mapMutations({
    //   setBandExportDialog: 'common/setBandExportDialog',
    // }),
    toDetail({ id }) {
      this.$router.push(`/transport/vehicleBill/detail?id=${id}`);
    },
    toWayBillDetail(data) {
      // PT004货客服没有运单列表 此处不允许跳转
      // if (data.tId && !['PT004'].includes(this.$store.state.common.AUV_ROLE_CODE)) {
      this.$router.push(`/transport/wayBill/detail?id=${data.qxTid}&gId=${data.gId}`);
      // }
    },
    openDialog(data) {
      this.clickOrderId = data.id;
      this.dialogVisible = true;
    },
    savePound() {
      this.$emit('savePound');
    },
    // 跳转运力监控
    openMap(data) {
      if (data.id) {
        this.$router.push(`/transport/monitor?id=${data.id}`);
      }
    },
    // 前端导出excel
    onExport() {
      // this.$baseMessage('导出准备，请稍等！', 'success');
      const params = {
        ...this.searchForm,
        state: this.searchForm.state ? this.searchForm.state.length ? this.searchForm.state.join(',') : null : null,
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      vehicleBill.orderCarTransCarOrdersExport(params).then(res => {
        const listData = res || [];
        if (!listData) {
          this.$baseMessage('暂无数据可导出!', 'error');
          return;
        }
        const header = ['车牌号', '司机姓名', '司机手机号', '司机身份证号', '承运商资质', '承运商姓名', '线路', '货主发单时间', '装货地址', '装货联系人', '卸货地址', '卸货联系人', '货物名称', '车队承运价格', '信息费', '含税方式', '合理损耗(‰)', '货物单价', '计费方式', '结算方式', '平台上报时间', '装货吨数', '实际装货时间', '卸货吨数', '实际卸货时间', '损耗/吨', '车单状态', '货单编号', '运单编号', '车单编号'];
        const exportData = listData.map((item) => {
          return {
            '车牌号': item.truckNo, // 车牌号
            '司机姓名': item.driverName, // 司机姓名
            '司机手机号': item.driverPhone, // 司机手机号
            '司机身份证号': item.driverIdcode, // 司机身份证号
            '承运商资质': item.carCustomerName, // 承运商资质
            '承运商姓名': item.carCustomerUserName, // 承运商姓名
            '线路': item.lineName, // 线路
            '货主发单时间': item.goodOrderCreateTime, // 货主发单时间
            '装货地址': item.loadAddr, // 装货地址
            // '装货联系人': item.loadContacter + ' ' + item.loadContacterPhone, // 装货联系人 李四 1771910166542
            '装货联系人': item.loadContacter, // 装货联系人 李四 1771910166542
            '卸货地址': item.unloadAddr, // 卸货地址
            // '卸货联系人': item.unloadContacter + ' ' + item.unloadContacterPhone, // 卸货联系人 李四 1771910166542
            '卸货联系人': item.unloadContacter, // 卸货联系人 李四 1771910166542
            '货物名称': item.goodsName || '-', // 货物名称
            '车队承运价格': item.price, // 车队承运价格
            '信息费': item.priceMiddle,
            '含税方式': item.needTax, // 含税方式
            '合理损耗(‰)': item.permitLose, // 合理损耗(‰)
            '货物单价': item.prodPrice, // 货物单价 元/吨
            '计费方式': item.billing, // 计费方式 按卸货吨数
            '结算方式': item.settlement, // 结算方式 卸货后30天、磅单原件
            // '运力标签': '黑货、白货', // 运力标签 黑货、白货
            '平台上报时间': item.reportTime, // 平台上报时间
            '装货吨数': item.loadReceiptNum, // 装货吨数 33.92
            '实际装货时间': item.loadTime, // 实际装货时间 2024/8/13 18:36:25
            '卸货吨数': item.unloadReceiptNum, // 卸货吨数
            '实际卸货时间': item.unloadTime, // 实际卸货时间
            '损耗/吨': item.loss, // 损耗/吨 0
            '车单状态': item.state, // 车单状态 运输完成
            '货单编号': item.goodsSerial, // 货单编号
            '运单编号': item.transSerial, // 运单编号
            '车单编号': item.carSerial, // 车单编号
          };
        });
        const data = exportData.map((product) => {
          return Object.values(product);
        });
        const exportDate = parseTime(new Date().getTime(), 'YYYYMMDDHHmmss');
        import('@/utils/export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: header,
            data,
            filename: '车单明细',
            merges: ['A1:AD1'],
            autoWidth: true,
            bookType: 'xlsx',
            needTitle: true,
            fileNameJoin: exportDate,
          });
          this.$baseMessage('导出成功！', 'success');
        });
      }).catch((error) => {
        console.log(error);
        this.$baseMessage('导出失败！', 'error');
      });
    },
    // 磅单导出
    bandOnExport() {
      const exportDate = parseTime(new Date().getTime(), 'YYYYMMDDHHmmss');
      if (this.$store.state.common.bandExportingLength > 10) {
        this.$baseMessage('磅单导出生成中已有十条，请稍后上传！', 'error');
        return;
      }
      const params = {
        taskNo: exportDate, // 任务编号
        taskName: exportDate, // 任务名
        fileName: exportDate + '.zip', // 文件名
        ...this.searchForm,
        state: this.searchForm.state ? this.searchForm.state.length ? this.searchForm.state.join(',') : null : null,
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      poundList.downloadPoundBill(params).then(res => {
        if (res) {
          this.$baseMessage('磅单导出操作成功！请点击右下角悬浮球进行下载查看', 'success');
        }
      }).catch((error) => {
        console.log(error, 'error');
        this.$baseMessage('磅单导出失败！', 'error');
      }).finally(() => {
        this.$store.dispatch('common/getBandExportIngNum', { var1: 1 });
      });
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
