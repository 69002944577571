<template>
  <div class="tab-list-container g-search-form-table">
    <search-form ref="form" :model="form" :count="12" label-width="auto" :collapse="true" @search="searchHandle">
      <search-item v-if="$store.state.common.AUV_ROLE_CODE === 'PT004'" label="" prop="gIds">
        <query-select
          v-model="form.gIds"
          placeholder="货单编号"
          width="650px"
          :values="form.gIds"
          value-key="id"
          value-label="goodsSerial"
          :default-label="goodsSerialSting"
          label-width="90px"
          clearable
          :search-fields="selectSearchFields.goodsSerial"
          :need-init-search-form="true"
          :init-search-form="initSearchForm"
          :remote="goodsSerialRemote"
          :multiple="true"
          @change="changeGoodsSerial"
        >
          <template>
            <el-table-column label="货单编号" prop="goodsSerial" />
            <el-table-column label="线路名称" prop="goodsSerial">
              <template slot-scope="{ row }">
                <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
              </template>
            </el-table-column>
            <el-table-column label="产品名称" prop="goodsName" />
          </template>
        </query-select>
      </search-item>
      <search-item v-else label="" prop="goodsSerial">
        <el-input v-model.trim="form.goodsSerial" clearable placeholder="货单编号" />
      </search-item>
      <search-item label="" prop="qxTSerial">
        <el-input v-model.trim="form.qxTSerial" clearable placeholder="运单编号" />
      </search-item>
      <search-item label="" prop="carSerial">
        <el-input v-model.trim="form.carSerial" clearable placeholder="车单编号" />
      </search-item>
      <search-item label="" prop="truckNo">
        <el-input v-model.trim="form.truckNo" clearable placeholder="车牌号码" />
      </search-item>
      <search-item label="" prop="driverName">
        <el-input v-model.trim="form.driverName" clearable placeholder="驾驶员姓名" />
      </search-item>
      <search-item label="" prop="customerName">
        <el-input v-model.trim="form.customerName" clearable placeholder="业务资质" />
      </search-item>
      <template #hide>
        <search-item label="" prop="loadTime">
          <el-date-picker v-model="form.loadTime" type="datetime" value-format="timestamp" placeholder="实际装货时间" />
        </search-item>
        <search-item label="" prop="unloadTime">
          <el-date-picker v-model="form.unloadTime" type="datetime" value-format="timestamp" placeholder="实际卸货时间" />
        </search-item>
        <search-item label="" prop="goodsName">
          <el-input v-model.trim="form.goodsName" clearable placeholder="产品名称" />
        </search-item>
        <search-item label="" prop="loadAddrName">
          <el-input v-model.trim="form.loadAddrName" clearable placeholder="装货地址" />
        </search-item>
        <search-item label="" prop="unloadAddrName">
          <el-input v-model.trim="form.unloadAddrName" clearable placeholder="卸货地址" />
        </search-item>
        <search-item label="" prop="state">
          <el-select
            v-model="form.state"
            placeholder="车单状态"
            multiple
            clearable
            collapse-tags
          >
            <el-option v-for="item in $CONSTANT.carOrderStateMap.A" :key="item.value" :label="item.label" :value="+item.value" />
          </el-select>
        </search-item>
      </template>
    </search-form>
    <div v-loading="loading" class="panel-wrapper">
      <div v-show="activeTab === 'list'" class="right-panel">
        <list-mode :table-data="listData" :search-form="form" @savePound="savePound" />
      </div>
    </div>
    <div
      v-if="listData.length>0"
      class="pb10 text-align-r"
    >
      <el-pagination
        layout="sizes, prev, pager, next, jumper, total"
        :total="pagination.total"
        :page-size="pagination.size"
        :current-page="pagination.page"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import ListMode from './components/list';
import goods from '@/api/transport/goods';
import vehicleBill from '@/api/transport/vehicleBill';
export default {
  name: 'TransportVehiclebill',
  components: { ListMode },
  data() {
    return {
      loading: false,
      form: {
        gIds: null,
      },
      activeTab: 'list',
      listData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 30,
      },
      goodsSerialSting: '',
      selectSearchFields: {
        goodsSerial: this.$store.state.common.AUV_ROLE_CODE === 'PT004' ? [
          { field: 'customerUserName', label: '发单人姓名', type: 'input' },
          { field: 'customerName', label: '资质名称' },
          { field: 'loadAddrName', label: '装货地址' },
          { field: 'unloadAddrName', label: '卸货地址' },
          { field: 'source', label: '货单类型', type: 'select', selectDataList: this.$CONSTANT.goodsSourceMap.A, selectObj: { label: 'label', value: 'value' }},
          { field: 'goodsName', label: '产品名称' },
          { field: 'goodsSerial', label: '货单编号' },
          { field: 'type', label: '报车类型', type: 'select', selectDataList: this.$CONSTANT.goodsTypeMap.A, selectObj: { label: 'label', value: 'value' }},
          { field: 'priority', label: '优先级', type: 'select', selectDataList: this.$CONSTANT.priorityArrayMap, selectObj: { label: 'label', value: 'value' }},
          { field: 'state', label: '货单状态', type: 'selectMultiple', selectDataList: this.$CONSTANT.goodsStateMap.SA, selectObj: { label: 'label', value: 'value' }},
          { field: 'createTime', label: '发单时间', type: 'daterange' },
        ] : [{ field: 'goodsSerial', label: '货单编号' }],
      },
      initSearchForm: {},
    };
  },
  created() {
    if (this.$route.query.fromGoodsList) {
      this.getGoodsListParams();
    } else {
      this.getList();
    }
  },
  methods: {
    toggleTab(tab) {
      this.activeTab = tab;
    },
    // 根据跳转获取到缓存的筛选条件 获取筛选结果展示在input框
    getGoodsListParams() {
      const params = JSON.parse(sessionStorage.getItem('goodListparams'));
      const newParams = {
        ...params,
        page: 1,
        size: 999,
      };
      this.goodsSerialRemote(newParams).then(res => {
        this.goodsSerialSting = res?.data.map(d => d.goodsSerial).join(',');
        this.form.gIds = res?.data.map(d => d.id);
      }).finally(() => {
        this.getList();
      });
      let newInitParams;
      // 筛选条件有时间则拼接成时间组件回显的数组格式
      if (params.beginCreateTime) {
        newInitParams = {
          ...params,
          createTime: [params.beginCreateTime, params.endCreateTime],
        };
      }
      // 初始化搜索项的值便于打开弹窗直接回显
      this.initSearchForm = newInitParams;
    },
    // 弹窗列表接口
    goodsSerialRemote(query) {
      // 如果有createTime 则需要将数组拆分成后台需要的字段
      if (query.createTime && query.createTime.length === 2) {
        query.beginCreateTime = query.createTime[0] || null;
        query.endCreateTime = query.createTime[1] + 86400000 - 1 || null;
      }
      // 时间不存在则表明无时间筛选项的值 此时清空两个时间字段
      if (query.createTime === null || (query.createTime && !query.createTime[0])) {
        delete query.beginCreateTime;
        delete query.endCreateTime;
      }
      if (query.createTime) {
        delete query.createTime;
      }
      return goods.orderGoodsTransList({
        ...query,
        state: query.state ? query.state.length ? query.state.join(',') : null : null,
        post: this.$store.state.common.AUV_ROLE_CODE,
      }).then((res) => {
        return {
          data: res?.records || [],
          total: res?.total || 0,
        };
      });
    },
    // 获取到弹窗表格点击的数据
    changeGoodsSerial(value, data) {
      this.form.gIds = value.length ? value : null;
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.form,
        state: this.form.state ? this.form.state.length ? this.form.state.join(',') : null : null,
        size: this.pagination.size,
        current: this.pagination.page,
        post: this.$store.state.common.AUV_ROLE_CODE,
      };
      vehicleBill.orderCarTransCarOrderList(params).then(res => {
        this.listData = res?.records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      }).finally(() => {
        this.loading = false;
      });
    },
    searchHandle() {
      this.pagination.page = 1;
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    savePound() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
