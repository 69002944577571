import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
// 详情左侧为组件 数据结构不一致 故转义同货单详情结构一致
export const getDetailType = {
  res: (d) => {
    return {
      ...d,
      goodsStop: d.extMap ? d.extMap.goodsStop || '-' : '-',
      tags: d.extMap.tags || '',
    };
  },
};
const prefix = '/auv';
export default {
  // 货单列表
  orderGoodsTransList: function(params) {
    return request.post(`${prefix}/order/goods/trans/list`, pickTrue(params));
  },
  // 货单列表导出
  orderGoodsTransExport: function(params) {
    return request.post(`${prefix}/order/goods/trans/export`, pickTrue(params));
  },
  // 承运货单车单列表
  orderCarTransGoodsList: function(params) {
    return request.post(`${prefix}/order/car/trans/goods/list`, pickTrue(params));
  },
  // 货单详情统计数据
  orderGoodsDetailCount: function(params) {
    return request.post(`${prefix}/order/goods/detail/count`, pickTrue(params));
  },
};
